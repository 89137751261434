import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from './environments/environment';
import { AppModule } from './app';


if (environment.production) {
  enableProdMode();
}
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((): void => {
    // Если в проекте используется роутинг, редирект или таймеры, регистрация ServiceWorker возможна только тут.
    // if ('serviceWorker' in navigator && environment.production) {
    //   navigator.serviceWorker.register('/sw-master.js').then((result) => {
    //     console.log('serviceWorker returns:', result);
    //   });
    // }
  })
  .catch((err: unknown): void => {
    console.log(`Загрузка ангуляр прервана ошибкой: ${err}`);
  })
  .finally((): void => {
    console.log('Ангуляр загружен и выполняется.')
  });
